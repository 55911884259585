import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Invoice from "../components/Invoice";
import useCheckIsMobile from "../hooks/useCheckIsMobile";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {callBackendAPI, getLabelValue, logError} from "../utils";
import {BackendResponse, IError, ISuccess} from "../types";


const Claim = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const sToken: string | null = searchParams.get("token");
    const transaction  = location?.state?.invoice;
    const checkData = location?.state?.data;
    const logIdx: number = location?.state?.log_idx;
    const navigate = useNavigate();
    const isMobile: boolean = useCheckIsMobile();
    const lang: string = useSelector((state: RootState) => state.home).lang;
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError>({error: false, message: ''});
    const [success, setSuccess] = useState<ISuccess>({success: false, message: ''});

    useEffect(() => {
       if (sToken && (transaction === undefined ||  logIdx === undefined)) {
            return navigate('/check');
       }
    },[])

    const claimInvoiceAndSubmitToIRBM = async (): Promise<void> => {
        try {
            setLoading(true);
            if (error.error)
                setError({ error: false, message: '' });
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            const input: any = {
                p1: sToken,
            }
            const response: BackendResponse | undefined = await callBackendAPI('einvoice/claiminvoice', 'POST', headers, input);
            if (response === undefined) {
                setLoading(false);
                return setError({ error: true, message: `001: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: `002: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.results === undefined) {
                setLoading(false);
                return setError({ error: true, message: `003: ${getLabelValue(lang, 'home_exception')}`});
            }
            const results: any = response.results;
            if (results?.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.message || `004: ${getLabelValue(lang, 'home_exception')}`
                });
            }

            return setSuccess({success: true, message: `${results?.message || 'Invoice has been submitted, please Check after 1 minute'} \n Invoice UUID: ${results?.invoiceId || ''}`})
        } catch (e) {
            logError('getTransactionDataByToken', e);
            if (loading)
                setLoading(false);
            return setError({ error: true, message: 'OOPS SOMETHING WENT WRONG'});
        }
    }

    return (
        <Invoice
            invoice={transaction}
            isMobile={isMobile}
            lang={lang}
            claimInvoice={async () => await claimInvoiceAndSubmitToIRBM()}
            isTransaction={true}
            loading={loading}
            error={error}
            onErrorClose={() => setError({ error: false, message: ''})}
            goBack={() => {
                navigate('/');
            }}
            success={success}
            onSuccessClose={() => {
                navigate(`/check?tin=${checkData?.tin || ''}&idtype=${checkData?.idtype || ''}&idnumber=${checkData?.idvalue || ''}&refno=${checkData?.refNo || ''}`);
            }}
        />
    )
}

export default Claim;
