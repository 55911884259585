import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import useCheckIsMobile from "../hooks/useCheckIsMobile";
import {CONSTANTS} from "../constants";
import {TItem, IError, BackendResponse} from "../types";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {callBackendAPI, getLabelValue, logError, stripCharactersAndTrim} from "../utils";
import Grid2 from "@mui/material/Unstable_Grid2";
import Invoice from "../components/Invoice";
import {useNavigate, useSearchParams} from "react-router-dom";


const Home = () => {
    const isMobile: boolean = useCheckIsMobile();
    const [searchParams] = useSearchParams();
    const sTin: string | null = searchParams.get('tin');
    const sIdType: string | null = searchParams.get('idtype');
    const sIdNumber: string | null = searchParams.get('idnumber');
    const sRefNo: string | null = searchParams.get('refno');

    const [idType, setIdType] = useState<string>(sIdType === null ? "" : sIdType);
    const [tin, setTin] = useState<string>(sTin === null ? "" : sTin);
    const [idvalue, setIdvalue] = useState<string>(sIdNumber === null ? "" : sIdNumber);
    const [refNo, setRefNo] = useState<string>(sRefNo === null ? "" : sRefNo);
    const lang: string = useSelector((state: RootState) => state.home).lang;
    const ID_TYPES: TItem[] = lang === 'en' ? CONSTANTS.IDENTIFICATION_TYPES_EN : CONSTANTS.IDENTIFICATION_TYPES_BM;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<IError>({error: false, message: ''});
    const [invoice, setInvoice] = useState<any>(undefined);
    const [claimInvoice, setClaimInvoice] = useState<boolean>(false);
    const navigate = useNavigate();

    const shouldLockEffect: React.MutableRefObject<boolean> = useRef<boolean>(false);
    useEffect(() => {
        if (shouldLockEffect?.current === false) {
            if (sTin !== null && sIdType !== null && sIdNumber !== null && sRefNo !== null) {
                submitData();
            }
        }
        return () => {
            shouldLockEffect.current = false;
        }
    }, [])

    const submitData = async (): Promise<void> => {
        try {
            if (tin === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_tin_required')});
            if (idType === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_idtype_required')});
            if (idvalue === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_idvalue_required')});
            if (refNo === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_refno_required')});
            if (error.error)
                setError({ error: false, message: '' })

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            const input: any = {
                tin,
                idtype: idType,
                idvalue,
                invoice_number: refNo
            }
            const response: BackendResponse | undefined = await callBackendAPI('einvoice/get', 'POST', headers, input);
            if (response === undefined) {
                setLoading(false);
                return setError({ error: true, message: `001: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: `002: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.results === undefined) {
                setLoading(false);
                return setError({ error: true, message: `003: ${getLabelValue(lang, 'home_exception')}`});
            }
            const results: any = response.results;
            if (results?.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.message || `004: ${getLabelValue(lang, 'home_exception')}`
                });
            }
            if (!Array.isArray(results?.results)) {
                setLoading(false);
                return setError({ error: true, message: `004: ${getLabelValue(lang, 'home_exception')}`});
            }
            const data: any = results?.results[0]?.data;
            setLoading(false);
            if (data === undefined || data.length === 0) {
                setClaimInvoice(true);
                return setError({ error: true, message: 'Invoice not Found'});
            }
            return setInvoice(data[0]);
        } catch (e: any) {
            logError("error submitData", e);
            if (loading) setLoading(false);
            setError({ error: true, message: `99: ${getLabelValue(lang, 'home_exception')}`})
        }
    }

    const resetState = (): void => {
        if (loading) setLoading(false);
        if (error.error) setError({ error: false, message: '' });
        if (invoice !== undefined) setInvoice(undefined);
        if (claimInvoice) setClaimInvoice(false);
        if (tin !== '') setTin('');
        if (idType !== '') setIdType('');
        if (idvalue !== '') setIdvalue('');
        if (refNo !== '') setRefNo('');
    }

    const getTransactionDetails = async () : Promise<void> => {
        try {
            setLoading(true);
            if (tin === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_tin_required')});
            if (refNo === '')
                return setError({ error: true, message: getLabelValue(lang, 'home_refno_required')});
            if (error.error)
                setError({ error: false, message: '' })

            setLoading(true);
            let headers: HeadersInit = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            const input: any = {
                t: tin,
                idt: idType,
                id: idvalue,
                r: refNo
            }
            const response: BackendResponse | undefined = await callBackendAPI('einvoice/gettransaction', 'POST', headers, input);
            if (response === undefined) {
                setLoading(false);
                return setError({ error: true, message: `001: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.error !== undefined) {
                setLoading(false);
                return setError({ error: true, message: `002: ${getLabelValue(lang, 'home_exception')}`});
            }
            if (response.results === undefined) {
                setLoading(false);
                return setError({ error: true, message: `003: ${getLabelValue(lang, 'home_exception')}`});
            }
            const results: any = response.results;
            if (results?.results?.code !== 0) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.results?.message || `004: ${getLabelValue(lang, 'home_exception')}`
                });
            }

            if (
                results?.token === undefined ||
                results?.results?.data === undefined ||
                results?.results?.log_idx === undefined
            ) {
                setLoading(false);
                return setError({
                    error: true,
                    message: results?.results?.message || `005: ${getLabelValue(lang, 'home_exception')}`
                });
            }
            return navigate(`/claim?token=${results?.token}`, {
                state: {
                    invoice: results?.results?.data,
                    data: {
                        tin: tin,
                        idtype: idType,
                        idvalue: idvalue,
                        refNo: refNo,
                    },
                    log_idx: results?.results?.log_idx
                }})
        } catch (e) {
            logError("error getTransactionDetails", e);
            if (loading) setLoading(false);
            setError({ error: true, message: `99: ${getLabelValue(lang, 'home_exception')}`})
        }
    }

    if (invoice !== undefined)
        return (
            <Invoice
                invoice={invoice}
                isMobile={isMobile}
                lang={lang}
                goBack={() => {
                   navigate('/')
                }}
            />
        )

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                backgroundImage: 'url("/images/background.jpg")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Card
                sx={{
                    width: isMobile ? '85%' :'40%',
                    padding: isMobile ? '10px' : '20px',
                }}
            >
                <CardContent>
                    <Grid2
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mb={4}
                    >
                        <img
                            src={require('../assets/mmbizlogo.png')}
                            style={{ width: isMobile ? '90%' : '60%' }}
                            alt="Mobile Money"
                        />
                    </Grid2>
                    <Box pb={2}>
                        <Typography variant="body1" gutterBottom>
                            {getLabelValue(lang, 'home_tin')}
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={tin}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                //let keyedValue = stripCharactersAndTrim(event.target.value, ' ', '');
                                //if (!isNaN(Number(keyedValue))) {
                                    setTin(event.target.value);
                                    if (invoice !== undefined) setInvoice(undefined);
                                    if (claimInvoice) setClaimInvoice(false);
                                    if (loading) setLoading(false);
                                    if (error.error) setError({ error: false, message: '' });
                                //}
                            }}
                            // inputProps={{
                            //     inputMode: 'numeric'
                            // }}
                        />
                    </Box>
                    <Box pb={2}>
                        <Typography variant="body1" gutterBottom>
                            {getLabelValue(lang, 'home_idtype')}
                        </Typography>
                        <Select<string>
                            fullWidth
                            value={idType}
                            onChange={(event: SelectChangeEvent<string>) => {
                                setIdType(event.target.value);
                                if (idvalue !== '') setIdvalue('');
                                if (invoice !== undefined) setInvoice(undefined);
                                if (claimInvoice) setClaimInvoice(false);
                                if (loading) setLoading(false);
                                if (error.error) setError({ error: false, message: '' });
                            }}
                            displayEmpty
                            variant="outlined"
                        >
                            {ID_TYPES.map((i) => {
                                return (
                                    <MenuItem key={i?.value} value={i?.value}>{i?.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </Box>
                    <Box pb={2}>
                        <Typography variant="body1" gutterBottom>
                            {getLabelValue(lang, 'home_idvalue')}
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={idvalue}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                let keyedValue = stripCharactersAndTrim(event.target.value, ' ','');
                                if (idType === 'NRIC' && isNaN(Number(keyedValue))) {
                                    return;
                                }

                                setIdvalue(keyedValue);
                                if (invoice !== undefined) setInvoice(undefined);
                                if (claimInvoice) setClaimInvoice(false);
                                if (loading) setLoading(false);
                                if (error.error) setError({ error: false, message: '' });
                            }}
                            inputProps={{
                                inputMode: idType === 'NRIC' ? 'numeric' : 'text'
                            }}
                        />
                    </Box>
                    <Box pb={2}>
                        <Typography variant="body1" gutterBottom>
                            {getLabelValue(lang, 'home_refno')}
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={refNo}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                let keyedValue = stripCharactersAndTrim(event.target.value, ' ', '');
                                if (!isNaN(Number(keyedValue))) {
                                    setRefNo(event.target.value);
                                    if (invoice !== undefined) setInvoice(undefined);
                                    if (claimInvoice) setClaimInvoice(false);
                                    if (loading) setLoading(false);
                                    if (error.error) setError({ error: false, message: '' });
                                }
                            }}
                            inputProps={{
                                inputMode: 'numeric'
                            }}
                        />
                    </Box>
                    {error.error && (
                        <Box>
                            <Alert
                                variant="filled"
                                severity="error"
                                onClose={() => {
                                    setError({ error: false, message: ''});
                                    resetState()
                                }}
                             >
                                {error.message}
                            </Alert>
                        </Box>
                    )}
                    {!claimInvoice ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Button
                                variant="contained"
                                sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                disabled={loading}
                                endIcon={loading ? <CircularProgress size={10} /> :null}
                                onClick={async () => await submitData()}
                            >
                                {getLabelValue(lang, 'home_submit_button')}
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                        >
                            <Button
                                variant="contained"
                                sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                onClick={() => resetState()}
                                color="error"
                            >
                                {getLabelValue(lang, 'home_cancel_button')}
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ marginTop: '20px', paddingX: 5, paddingY: 1 }}
                                disabled={loading}
                                endIcon={loading ? <CircularProgress size={10} /> :null}
                                onClick={async () => await getTransactionDetails()}
                                color="success"
                            >
                                {getLabelValue(lang, 'home_claim_button')}
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default Home;
